@import url("https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,900&display=swap");

body {
  font-size: 14px;
  color: #716e6e;
  font-family: "Raleway", sans-serif !important;
  background: url("../images/casper-bg.jpg") no-repeat 50% fixed!important;
  background-size: cover !important;
  font-weight: 300;
  line-height: 22px;
  padding-bottom: 100px;
  min-height: 100%;
  position: relative;
  margin: 0;
}
/* Dynamic Css Start  */

.white-box {
  border: 4px solid #1e306e;
  background: hsla(0,0%,100%,.85);
  /* background: hsla(0,0%,100%,.9); */

  padding: 30px 30px;
  border-width: 4px 0px;
}
.white-box h1 {
  color: #1e306e;
  font-size: 27px;
  font-weight: 700;
  text-align: left;
  margin-bottom: 20px;
}
.white-box.thank {
  text-align: center;
}

.white-box-n img {
  margin-bottom: 20px;
}

.white-box-n {
  text-align: center;
}

.white-box-n button:hover {
  background: #16419c;
  border: 1px solid #223d99;
}

.white-box-n button {
  background: #00a8e2;
  border: 1px solid #00a8e2;
  outline: none;
  color: #fff;
  padding: 6px 40px;
  font-weight: 700;
  font-size: 16px;
  text-transform: uppercase;
  border-radius: 20px;
}

/* Dynamic Css End  */

html {
  height: 100%;
  box-sizing: border-box;
}

form#signin {
  margin-bottom: 0px;
}



i.zmdi.zmdi-assignment-check.circle {
  width: 120px;
  height: 120px;
  font-size: 52px;
  border: 2px solid #04b954;
  border-radius: 50%;
  padding: 30px;
  margin: 40px auto 30px;
  color: #04b954;
  margin: 40px auto;
  display: block;
}

.mt-2 {
  margin-top: 20px;
}

a {
  color: #000;
  text-decoration: none;
}

a:hover {
  color: #000;
  text-decoration: underline;
}

ul.points li:first-child {
  border: none;
}

h1 {
  color: #000;
  font-family: "Raleway", sans-serif;
}

h2,
h3,
h4,
h5,
h6 {
  color: #000;
  font-family: "Raleway", sans-serif;
}

.glyphicon-search:before {
  content: "\e003";
  font-family: "Glyphicons Halflings";
}

select {
  outline: none !important;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin-top: 0;
  padding: 0px;
  margin-right: 0;
  margin-bottom: 10px;
  margin-left: 0;
}

p {
  line-height: 20px;
  color: #000;
  font-weight: 400;
}

h1 {
  font-size: 30px;
}

h2 {
  font-size: 26px;
}

h3 {
  font-size: 24px;
}

h4 {
  font-size: 20px;
}

h5 {
  font-size: 18px;
}

h6 {
  font-size: 16px;
}

:before,
:after {
  font-family: Material-Design-Iconic-Font;
}

p.note {
  background: #1e306e;
  padding: 14px;
  color: #fff;
}

ul.info-data {
  padding: 0px;
  list-style: none;
  margin: 10px auto;
  display: inline-block;
}

ul.info-data li {
  border: 1px solid #ccc;
  color: #212121;
  margin-top: -1px;
  padding: 4px 52px;
  font-weight: 400;
}

.clear:after {
  content: "";
  width: 100%;
  clear: both;
  display: table;
}

.check-boexs {
  float: left;
  margin-bottom: 20px;
  width: 24.3% !important;
}
.logo {
  background: hsla(0,0%,100%,.8509803921568627);
  /* background: hsla(0,0%,100%,.9); */
  margin: 40px auto;
  padding-top: 2px;
  padding-bottom: 2px;
}

.logo img, form.form-outer label span.carddetail {
    display: block;
}
.logo img {
    margin: 0 auto;
    width: 360px;
}

.carousel-inner>.item>a>img, .carousel-inner>.item>img, .img-responsive, .thumbnail a>img, .thumbnail>img {
  display: block;
  height: auto;
  max-width: 100%;
}

form.form-outer label span.carddetail {
  display: block;
}

.red_point {
  color: #f00;
  font-size: 23px;
}

.error_message_forimage {
  width: auto !important;
  padding: 0 !important;
  float: left;
}

.input-outer {
  position: relative;
  margin: 20px 0px;
}


p.logo-text {
  font-size: 45px;
  line-height: 1.4;
  font-weight: 700;
  color: #3e3934;

  margin-top: 13px;
}

p.error {
  color: #e00d0d !important;
  border-radius: 30px;
  padding: 0px 0px !important;
  display: inline-block;
}

.footer {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  text-align: center;
}

button#pop {
  border-color: #fff0;
}

div#inlineContent img {
  width: 300px;
}

div#ob_content p {
  text-align: center;
}

.white-box.form h2 {
  border: 1px solid #ccc;
  margin-top: 25px;
  padding: 10px 0px;
  border-width: 1px 0px 1px 0px;
}

form.form-outer.register label span {
  color: #f00;
  font-size: 23px;
}

.phone-outer input {
  width: 30% !important;
}

input#choicekw0 {
  width: 10%;
  display: block;
  margin: 0 auto;
}

input#choicekw1 {
  width: 10%;
  display: block;
  margin: 0 auto;
}

a#userinfo {
  color: #4040bb !important;
  text-decoration: underline !important;
  outline: none !important;
}

input#choicekw2 {
  width: 10%;
  display: block;
  margin: 0 auto;
}

div#font-style {
  font-family: var(--bs-font-sans-serif);
}
form.form-outer.register select {
  background: #ffffff00;
  width: 100%;
  margin-top: 10px;
  margin-bottom: 10px;
  outline: none;
  padding-left: 20px;
  border-radius: 30px;
  height: 46px;
  border: 1px solid #231f20;
}

input#choicekw3 {
  width: 10%;
  display: block;
  margin: 0 auto;
}

.reward1 img {
  width: 120px;
}

.reward1 input[type="radio"] {
  width: 100%;
  outline: none;
  margin-bottom: 2px;
}

.reward1 {
  max-height: 310px;
  text-align: center;
  border: 1px solid #ccc;
  padding: 10px;
  background: #fff;
  margin-bottom: 10px;
  height: 200px;
}

.reward {
  text-align: center;
  border: 1px solid #ccc;
  padding: 10px;
  background: #fff;
  margin-bottom: 10px;
  /* height: 295px; */
  border-radius: 10px;
}

.reward_cert {
  text-align: center;
  padding: 10px;
  margin-bottom: 10px;
}

form.form-outer.register button {
  position: static !important;
  border-radius: 30px;
  height: 46px;
  width: 110px;
  background: #231f20;
  border: 1px solid #211d1e;
  color: #fff;
  margin-top: 31px;
  float: right;
}

img#popImg {
  width: 300px;
  align-items: center;
  margin: auto;
}

form.form-outer.register button:hover {
  background: #cb2330;
  border: 1px solid #cb2330;
}

.modelContent {
  margin-top: 20% !important;
}

@media (min-width: 576px) {
  div#modelContent {
    max-width: 889px !important;
    margin: 1.75rem auto !important;
  }
}

@media (min-width: 1200px) and (max-width: 1910px) {
  div#modelContent {
    max-width: 780px !important;
    margin: 1.75rem auto !important;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  div#modelContent {
    max-width: 500px !important;
    margin: 1.75rem auto !important;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  div#modelContent {
    max-width: 360px !important;
    margin: 1.75rem auto !important;
  }
}

@media (min-width: 320px) and (max-width: 767px) {
  div#modelContent {
    max-width: 270px !important;
    margin: 1.75rem auto !important;
    margin-left: 36px auto !important;
  }

  img#popImg {
    width: 200px;
    align-items: center;
    margin: auto;
  }
}

@media (min-width: 150px) and (max-width: 321px) {
  div#modelContent {
    max-width: 190px !important;
    margin-left: 37px !important;
  }

  img#popImg {
    width: 150px;
    align-items: center;
    margin: auto;
  }
}

/*Responsive table*/
/*======
Media Queries
=======*/
/*========================*/
@media (min-width: 1200px) and (max-width: 1910px) {
  /* .container-fluid{width:60%;} */
}

@media (min-width: 992px) and (max-width: 1199px) {
  .container-fluid {
    width: 90%;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .reward_cert img {
    width: 150px;
    display: block;
    margin: 0 auto;
    margin-top: 5px;

  }

  p.logo-text {
    float: right;
    font-size: 45px;
    line-height: 1.4;
    font-weight: 700;
    color: #3e3934;

    margin-top: 13px;
  }

  ul.points li img {
    margin-bottom: 10px;
  }

  .container-fluid {
    width: 90%;
  }

  .check-boexs {
    float: none;
    margin-bottom: 20px;
    width: 100% !important;
  }

  .reward img {
    width: 150px;
    display: block;
    margin: 0 auto;
    margin-top: 5px;

  }
}

@media (min-width: 320px) and (max-width: 767px) {
  .white-box {
    text-align: center;
  }

  .reward_cert img {
    width: 100px;
    display: block;
    margin: 0 auto;
    margin-top: 5px;
  }

  p.logo-text {
    text-align: center;
    font-size: 35px;
    line-height: 1.3;
    font-weight: 700;
    color: #3e3934;
    margin-top: 13px;
    margin-bottom: 30px;
  }

  .phone-outer input {
    width: 28.6% !important;
  }

  ul.points li img {
    margin-bottom: 10px;
  }

  .container-fluid {
    width: 90%;
  }

  .check-boexs {
    float: none;
    margin-bottom: 20px;
    width: 100% !important;
  }

  .reward img {
    width: 150px;
    display: block;
    margin: 0 auto;
    margin-top: 5px;
  }

  .modal-content {
    width: 94% !important;
  }
}

@media (min-width: 320px) and (max-width: 400px) {
  p.logo-text {
    text-align: center;
    font-size: 45px;
    line-height: 1.4;
    font-weight: 700;
    color: #3e3934;

    margin-top: 13px;
  }

  .phone-outer input {
    width: 28.6% !important;
  }

  .check-boexs {
    float: none;
    margin-bottom: 20px;
    width: 100% !important;
  }

  .reward img {
    width: 150px;
    display: block;
    margin: 0 auto;
    margin-top: 5px;
  }
}

@media (min-width: 320px) and (max-width: 480px) {
  p.logo-text {
    text-align: center;
    font-size: 45px;
    line-height: 1.4;
    font-weight: 700;
    color: #3e3934;

    margin-top: 13px;
  }

  .phone-outer input {
    width: 28.6% !important;
  }

  .check-boexs {
    float: none;
    margin-bottom: 20px;
    width: 100% !important;
  }

  .reward img {
    width: 150px;
    display: block;
    margin: 0 auto;
    margin-top: 5px;
  }
}

h1 {
  text-align: center;
}

.phonenumber {
  font-size: 14px;
  font-family: Arial;
}

.overflow-hider {
  width: 100%;
  overflow-y: hidden;
  overflow-x: auto;
}
.recap {
  width: 304px;
  height: 78px;
}

.styling {
  width: 250px;
  height: 40px;
  border: 1px solid rgb(193, 193, 193);
  margin: 10px 25px;
  padding: 0px;
  resize: none;
  display: none;
}

i.zmdi.zmdi-assignment-check.circle {
  width: 120px;
  height: 120px;
  font-size: 52px;
  border: 2px solid #04b954;
  border-radius: 50%;
  padding: 30px;
  margin: 40px auto 30px;
  color: #04b954;
  margin: 40px auto;
  display: block;
}
svg {
  width: 120px;
  height: 120px;
  font-size: 52px;
  border: 2px solid #04b954;
  border-radius: 50%;
  padding: 30px;
  margin: 40px auto 30px;
  color: #04b954;
  margin: 40px auto;
  display: block;
}

ul.points li {
  list-style: none;
  font-size: 15px;
  color: #313131;
  padding: 17px 0px;
  font-weight: 400;
  border-bottom: 1px solid #e4e4e4;
}

@media (min-width: 1200px) and (max-width: 1910px) {
  .container-fluid {
    width: 100%;
  }
}

b,
strong {
  font-weight: 700;
}
@media (min-width: 320px) and (max-width: 767px) {
  img.card {
    margin: 0 auto !important;
  }

  .container-fluid {
    width: 90% !important;
  }

  .white-box {
    width: 100%;
    text-align: center;
  }

  .white-box h1 {
    text-align: center;
    font-size: 22px;
  }

  input#submit {
    margin-left: auto !important;
    margin-right: auto;
    width: 100%;
  }

}

@media (min-width: 768px) and (max-width: 991px) {
  .container-fluid {
    width: 90% !important;
  }

  .white-box {
    width: 100%;
  }

}

@media (min-width: 992px) and (max-width: 1199px) {
  .white-box {
    width: 100%;
  }
}

@media (min-width: 1911px) and (max-width: 5000px) {
  .container-fluid {
    width: 900px !important;
  }

  .white-box {
    width: 100%;
  }
}

@media (min-width: 320px) and (max-width: 480px) {
  img.card {
    margin: 0 auto !important;
  }

  .container-fluid {
    width: 90% !important;
  }
}

label.formsub {
  max-width: 100%;
  font-size: 15px;
  margin-bottom: 5px;
  font-weight: 400;
  text-transform: unset;
}

.box {
  padding: 15px;
  margin: 15px;
  background-color: #00a8e2;
}

@media (min-width: 320px) and (max-width: 575px) {
  .check-boexs {
    width: 100%;
    text-align: center;
  }
}

@media (min-width: 1200px) and (max-width: 1910px) {
  .container-fluid {
    width: 60% !important;
  }
}

.header_lower {
  text-align: center;
  font-size: 22px;
  /* font-size: 17px; */
  color: #fff;
  margin-bottom: 22px;
  width: 100%;
  background-color: #526699;
  padding: 15px;
  margin: 0 auto;
}

.modal {
  background-color: #0000003d;
}

.modal-header {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
  background-color: #1e306e !important;
  color: #fff;
}



:before,
:after {
  font-family: Material-Design-Iconic-Font;
}

ul.terms-list li:before {
  content: "\f2fb";
  position: absolute;
  left: 12px;
  color: #3e3936;
}

ul.terms-list {
  list-style: none;
  font-size: 15px;
  font-weight: 400;
}

.terms-list1 {
  list-style: none;

}

button#pop {
  border: #fff;
  background-color: #fff0;
  color: #4040bb;
  text-decoration: underline;
  margin-left: -7px;
  display: inline-block;
  width: auto;
}

button#pop:hover {
  color: #0e66e9;
}

label.agree.label div {
  display: inline;
}

button#pop-close1 {
  background-color: #526699;
  border: 2px solid #fff ;
  border-radius: 50% ;
  font-weight: bold ;
  font-size: 1.5rem;
  margin-left: -20px;
  margin-top: -21px;
  padding: 0;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

button#pop-close {
  background-color: #526699;
  border: none ;
  border-radius: 50%;
  font-weight: bold;
  font-size: 1.5rem;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.modal-content {
  box-shadow: 0 5px 15px rgb(0 0 0 / 70%);
}
div#card-section {
  margin-top: 4%;
}
.model-body {
  padding-right: 36px;
  padding-bottom: 15px;
  padding-top: 15px;
}
li {
  line-height: 22px;
}
.llist {
  list-style: none;
  text-align: center;
  font-weight: 400;
}
#note {
  text-align: center;
}
.modal-header h4 {
  color: #fff;
  font-size: 20px;
}
#header {
  border-bottom: none !important;
  background-color: #fff !important;
}
button#closeUp {
  margin-left: -81px;
  margin-top: -55px;
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.5;
}

label.heading1 {
  display: inline;
}

label.heading1.ml-5 {
  margin-left: 50px;
}

label.heading1.ml-3 {
  margin-left: 80px;
}

#card_img {
  text-align: center;
}

.cards {
  text-align: center;
}


/* card CSS */
.pirds {
  margin: 0;
  padding: 0;
  width: 127px;
  height: 73px;
  padding: 0px;
  color: #fff;
  text-decoration: none;
  display: inline-flex;
  flex-wrap: wrap;
  text-align: center;
  -webkit-border-radius: 23px;
  -moz-border-radius: 23px;
  border-radius: 23px;
  margin-right: 3px;
  background: #2b85c6;
  background: -webkit-gradient(linear, 0 0, 0 bottom, from(#2b85c6), to(#1f69af));
  background: -webkit-linear-gradient(#2b85c6, #1f69af);
  background: -moz-linear-gradient(#2b85c6, #1f69af);
  background: -ms-linear-gradient(#2b85c6, #1f69af);
  background: -o-linear-gradient(#2b85c6, #1f69af);
  background: linear-gradient(#2b85c6, #1f69af);
}

.form-check.form-switch .pirds {
  display: flex;
  margin: 0 auto;
}

.rdcrdtop {
  width: 100%;
}

.rdcrdtop table {
  border: none;
  width: 100%;
  text-align: center;
}


.mws-table tbody td:first-child,
.mws-table tfoot td:first-child {
  border-left: none;
}

.rdcrdtop table tr td {
  margin: 0;
  padding: 0;
  text-decoration: none !important;
  color: #fff;
  font-family: Arial, Helvetica, sans-serif !important;
  font-size: 12px;
  display: block;
  line-height: 18px;
  width: 100%;
  height: 50px;
  text-align: center;
  text-transform: uppercase;
  font-weight: normal;
  background: none !important;
  display: table-cell;
  border-right-width: 0px !important;
}

.rdcrd {
  margin: 0;
  padding: 0;
  text-decoration: none !important;
  color: #F3EB31;
  font-family: Arial, Helvetica, sans-serif !important;
  font-size: 12px;
  width: 100%;
  display: block;
  text-align: center;
  font-stretch: condensed;
  text-transform: uppercase;
}

@media (min-width: 1911px) and (max-width: 10000px) {
  .container-fluid {
    width: 900px !important;
  }
}

span.blue {
  word-break: break-word;
}
@media (min-width: 150px) and (max-width: 321px){
  div#modelContent {
      max-width: 262px!important;
      margin-left: 37px!important;
  }
  }
  a {
    word-break: break-word;
}
p.register-p{
  margin-bottom: 0;
  text-align: center;
  font-weight: 400;
  font-family: var(--bs-font-sans-serif);
}
p.thank-p{
  text-align: center;
  font-weight: 400;
  font-family: var(--bs-font-sans-serif);
}


.alert.alert-info {
  color: #004085;
  background-color: #cce5ff;
  border-color: #cce5ff;
  margin-top: 25px;
  font-weight: 400;
  margin-bottom: 18px;
  font-family: var(--bs-font-sans-serif);
}
h2.register_lower {
  color: #1e191a !important;
  /* font-size: 17px !important; */
  font-size: 16px !important;
  font-weight: 800 !important;
}

li {
  list-style: none;
}