/* Register Page Button CSS */
input.sub-btn:hover {
  background: #526699 !important;
  border: 1px solid #526699 !important;
}
input.sub-btn {
  display: flex;
  justify-content: center;
  color: #fff;
  padding: 10px 100px;
  border-radius: 30px;
  top: 37px;
  border: 1px solid #1e306e;
  background: #1e306e !important;
  text-align: center;
  margin: 0 auto;
  margin-top: 20px;
}

/* Survey Page Button CSS */
input.submit-btn.survey {
  background: #1e306e;
  outline: none;
  color: #fff;
  padding: 7px 15px !important;
  border: 1px solid #1e306e;
  border-radius: 50px;
}
input.submit-btn.survey:hover {
  background: #526699;
  border: 1px solid #526699;
}
input.sub-btn1 {
  background: #1e306e !important;
  outline: none;
  color: #fff;
  padding: 7px 15px;
  border: 1px solid #1e306e !important;
  border-radius: 50px;
}

input.sub-btn1:hover {
  background: #526699 !important;
  border: 1px solid#526699 !important;
}

button#btn1 {
  margin-right: 45%;
  color: #fff !important;
  border: 1px solid #1e306e;
  border-radius: 20px;
  outline: none !important;
  background-color: #1e306e !important;
}
button#btn1:hover {
  background: #526699 !important;
  outline: none !important;
  border: 1px solid #526699 !important;
}

a.btn.btn-md.btn-primary {
  position: static !important;
  outline: none !important;
  border-radius: 30px !important;
  height: 46px !important;
  padding: 8px !important;
  width: 118px !important;
  background: #1e306e !important;
  border: 1px solid #1e306e !important;
  color: #fff !important;
  float: right !important;
}

a.btn.btn-md.btn-primary:hover {
  background: #526699 !important;
  border: 1px solid #526699 !important;
  text-decoration: none !important;
}


input.sub-btn2 {
  background: #1e306e !important;
  border: 1px solid #1e306e !important;
  outline: none;
  color: #fff;
  padding: 11px 15px;
  /* border-radius: 50px; */
  border-radius: 10px;
  width: 100%;
}
input.sub-btn2:hover {
  background: #526699 !important;
  border: 1px solid #526699 !important;
}
a#Thanku_Buttons {
    background-color: #1e306e;
    border-color: #1e306e;
    color: #fff !important;
    text-decoration: none !important;
}
a#Thanku_Buttons:hover {
  background: #526699 !important;
  border: 1px solid #526699 !important;
}